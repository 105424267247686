<template>
    <div class="row child-component banking-info" v-if="ProdEXPAmend.ChangeType == 'Banking' || ProdEXPAmend.ChangeType == 'Both'">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">

            <div class="d-block row col-lg-8 mx-auto">
                <div>
                    <div class="fieldborder">
                        <label>Bank Name</label>
                        <b-form-input @focus="ResetDirty('BankName')" @blur="SetDirty('BankName')" autocomplete="off"
                            id="bank-name" class="omniipclass" v-model="ProdEXPAmend.BankName" placeholder="Enter Bank Name"
                            :maxlength="99">
                        </b-form-input>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.BankName.$dirty && !$v.ProdEXPAmend.BankName.required">
                            Required field
                        </div>
                    </div>

                    <div class="fieldborder">
                        <label>BANK COUNTRY</label>
                        <OmniSelect @focus="ResetDirty('BankCountry')" @blur="SetDirty('BankCountry')"
                            class="pt-0 mb-0 omniipclass omniindexclass" placeholder="Select Country" name="BankCountry"
                            :showLabel="true" label="BankCountry" v-model="ProdEXPAmend.BankCountry" :options="Countryilst">
                        </OmniSelect>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.BankCountry.$dirty && !$v.ProdEXPAmend.BankCountry.required">
                            Required field
                        </div>
                    </div>

                    <div class="fieldborder">
                        <label>BANK ADDRESS LINE 1 </label>
                        <b-form-input @focus="ResetDirty('BankAddressLine1')" @blur="SetDirty('BankAddressLine1')"
                            autocomplete="off" id="bankaddressline1" class="omniipclass"
                            v-model="ProdEXPAmend.BankAddressLine1" placeholder="Enter Bank Address Line1"
                            :maxlength="26"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.BankAddressLine1.$dirty && !$v.ProdEXPAmend.BankAddressLine1.required">
                            Required field
                        </div>
                    </div>
                    <div class="fieldborder">
                        <label>BANK ADDRESS LINE 2 </label>
                        <b-form-input @focus="ResetDirty('BankAddressLine2')" @blur="SetDirty('BankAddressLine2')"
                            autocomplete="off" id="bankaddressline2" class="omniipclass"
                            v-model="ProdEXPAmend.BankAddressLine2" placeholder="Enter Bank Address Line2"
                            :maxlength="26"></b-form-input>
                    </div>

                    <div class="fieldborder">
                        <label for="city" class="lbltxt" v-if="IsCountryUSA">CITY</label>
                        <b-form-input @focus="ResetDirty('BankCity')" @blur="SetDirty('BankCity')" autocomplete="off"
                            id="billingCity" class="omniipclass" v-if="IsCountryUSA" v-model="ProdEXPAmend.BankCity"
                            placeholder="Enter City" :maxlength="30"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.BankCity.$dirty && !$v.ProdEXPAmend.BankCity.required">
                            Required field
                        </div>
                    </div>

                    <div class="row fieldborder omniipclass" v-if="IsCountryUSA" style="
                              padding-bottom: 69px !important;
                              padding-top: 18px !important;
                              margin-top: -24px;
                            ">
                        <div class="col-md-8" style="margin-top: -24px !important">
                            <label v-if="IsCountryUSA" for="BankState" class="lbltxt">State</label>
                            <OmniSelect @blur="SetDirty('BankState')" @focus="ResetDirty('BankState')"
                                class="pt-0 omniindexclass omniindexclass" v-if="IsCountryUSA" placeholder="Select State"
                                name="BankState" :showLabel="true" label="STATE" :showSearch="true"
                                v-model="ProdEXPAmend.BankState" :options="StateList">
                            </OmniSelect>
                            <div class="error-message-format"
                                v-if="$v.ProdEXPAmend.BankState.$dirty && !$v.ProdEXPAmend.BankState.required">
                                Required field
                            </div>
                        </div>
                        <div class="col-md-4" style="margin-top: -24px !important">
                            <label v-if="IsCountryUSA" for="BankZipCode" class="lbltxt">ZIP CODE</label>

                            <b-form-input autocomplete="off" id="BankZipCode" class="" :minlength="5" v-if="IsCountryUSA"
                                style="width: 135px !important" :maxlength="5" v-bind:class="{
                                    redtextbox: (validationtextbox || !$v.ProdEXPAmend.BankZipCode.numeric) && $v.ProdEXPAmend.BankZipCode.$dirty,
                                    notredtextbox: !validationtextbox && $v.ProdEXPAmend.BankZipCode.numeric,
                                }" @blur="SetDirty('BankZipCode')" @focus="ResetDirty('BankZipCode')"
                                @keyup="ValidateZipCode()" v-model="ProdEXPAmend.BankZipCode"
                                placeholder="Enter Zip Code"></b-form-input>
                            <div v-show="validationtextbox && $v.ProdEXPAmend.BankZipCode.$dirty" style="width:135px;"
                                class="error-message-format">
                                Input must be 5 characters
                            </div>
                            <div v-show="!$v.ProdEXPAmend.BankZipCode.numeric && $v.ProdEXPAmend.BankZipCode.$dirty"
                                style="width:135px;" class="error-message-format">
                                This field can only contain numerical values
                            </div>
                        </div>
                    </div>

                    <div class="fieldborder">
                        <label>BANK ACCOUNT NAME </label>
                        <b-form-input @focus="ResetDirty('AccountName')" @blur="SetDirty('AccountName')" autocomplete="off"
                            id="accountname" class="omniipclass" v-model="ProdEXPAmend.AccountName"
                            placeholder="Enter Bank Account Name" :maxlength="99"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.AccountName.$dirty && !$v.ProdEXPAmend.AccountName.required">
                            Required field
                        </div>
                    </div>

                    <div class="fieldborder">
                        <label>BANK ACCOUNT NUMBER </label>
                        <b-form-input @focus="ResetDirty('AccountNumber')" @blur="SetDirty('AccountNumber')"
                            autocomplete="off" id="accountnumber" class="omniipclass" v-model="ProdEXPAmend.AccountNumber"
                            placeholder="Enter Bank Account Number" :maxlength="99"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.AccountNumber.$dirty && !$v.ProdEXPAmend.AccountNumber.required">
                            Required field
                        </div>
                    </div>
                    <div class="fieldborder" v-if="IsCountryUSA">
                        <label>Routing Number (US Only) </label>
                        <b-form-input @focus="ResetDirty('RoutingNumber')" @blur="SetDirty('RoutingNumber')"
                            autocomplete="off" id="routingnumber" class="omniipclass" v-model="ProdEXPAmend.RoutingNumber"
                            placeholder="Enter Routing Number (US Only)" :maxlength="9"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.RoutingNumber.$dirty && !$v.ProdEXPAmend.RoutingNumber.required">
                            Required field
                        </div>
                    </div>
                    <div class="fieldborder" v-if="!IsCountryUSA">
                        <label>Sort/Swift (Non US) </label>
                        <b-form-input @focus="ResetDirty('BankBIC')" @blur="SetDirty('BankBIC')" autocomplete="off"
                            id="sortswift" class="omniipclass" v-model="ProdEXPAmend.BankBIC"
                            placeholder="Enter Sort/Swift (Non US)" :maxlength="99"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.BankBIC.$dirty && !$v.ProdEXPAmend.BankBIC.required">
                            Required field
                        </div>
                    </div>
                    <div class="fieldborder" v-if="!IsCountryUSA">
                        <label>IBAN (Non US)</label>
                        <b-form-input @focus="ResetDirty('BankIBAN')" @blur="SetDirty('BankIBAN')" autocomplete="off"
                            id="iban" class="omniipclass" v-model="ProdEXPAmend.BankIBAN" placeholder="Enter IBAN (Non US)"
                            :maxlength="99"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.BankIBAN.$dirty && !$v.ProdEXPAmend.BankIBAN.required">
                            Required field
                        </div>
                    </div>

                    <div class="fieldborder">
                        <label>Remittance Advice Email</label>
                        <b-form-input @focus="ResetDirty('RemittanceAdviceEmail')" @blur="SetDirty('RemittanceAdviceEmail')"
                            autocomplete="off" id="remittanceemail" class="omniipclass"
                            v-model="ProdEXPAmend.RemittanceAdviceEmail" placeholder="Enter Remittance Advice Email"
                            :maxlength="99"></b-form-input>
                        <div class="error-message" v-if="!$v.ProdEXPAmend.RemittanceAdviceEmail.email">
                            Please enter a valid email address (eg. test@FinSys.com)
                        </div>
                    </div>
                    <div class="fieldborder mb-n4">
                        <b-form-group>
                                <label>Bank Letter/Voided Check or Invoice Copy with ACH Info</label>
                            <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9"
                                version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                v-b-tooltip.hover.v-dark.right.html="'Due to internal audit controls, we are required to obtain a bank letter for all banking changes on vendors. An invoice copy will only be accepted if the ACH banking info is reflected on it.'
                                    ">
                                <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path
                                        d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                                        id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                                </g>
                            </svg>
                            <vue-dropzone v-on:vdropzone-mounted="mountedBankLetterDropzone" :duplicateCheck="true"
                                ref="myVueDropzoneBankLetter" id="myVueDropzoneBankLetter" :useCustomSlot="true"
                                class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                                v-on:vdropzone-file-added="UploadedtoBankLetter"
                                v-on:vdropzone-removed-file="RemovedFromBankLetter">
                                <div class="dropzone-custom-content">
                                    <p class="Drag-and-drop-file-h">
                                        <b-img style="margin-top: -7px !important;font-family: 'Open Sans';"/>
                                        Drag and drop file here or
                                        <span class="draganddropspan"> browse </span>
                                    </p>
                                </div>
                            </vue-dropzone>
                        </b-form-group>
                    </div>
                </div>

            </div>
        </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                Previous
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()"
                :disabled="this.$v.$invalid || forminvalid()">
                NEXT
            </button>
        </div>
        <b-modal v-model="ShowPaymentInfo" hide-footer :no-close-on-backdrop="true">
            <div>
                <p>
                    If no, vendor will be paid by Check to the address provided on the W9.
                </p>
            </div>
            <template v-slot:modal-header="{ close }">
                <h5 class="modal-title">Note</h5>
                <button class="close" @click="CancelPaymentInfo()"></button>
            </template>

            <div class="text-right">
                <button class="text-uppercase btn custom-button btn-primary" @click="CancelPaymentInfo()">
                    Ok
                </button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { required, requiredIf, numeric, minLength, maxLength, helpers } from "vuelidate/lib/validators";

import {
    mapGetters
} from "vuex";
import service from "../../../services/Dropdowns";
const EmailRegEx = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-\x7f])+)\])$/
const EmailWithoutCase = helpers.regex('EmailWithoutCase', EmailRegEx)

export default {
    components: {
        vueDropzone: vue2Dropzone,
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            CountryUnitedStatesValue: "USA - United States",
            BankLetterFile: 0,
            IsBankLetterLoadedInEdit: false,
            ShowPaymentInfo: false,
            deleteIcon: require("@/assets/Icons/delete.svg"),
            closeFilterIcon: require("../../../assets/Icons/file.png"),
            dropzoneOptions: {
                paramName: "file",
                //params: {'param_1':'xyz','para_2': localid },
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/mdm/Upload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                //  headers:{"Authorization":'Bearer eyJraWQiOiJRS2V5YUxEY2RuQm9YKzlibm4rTGJQbjQrMVhiMjFuR2hHQ0l2aHpPQmJrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicldwdUNRbV9PenBvZjhwUG1iOFg4QSIsInN1YiI6IjgzNWU1NGQxLWY4YzMtNDJiYy1hZGQ2LTI4Y2RmZjdiNTc4ZiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV9yemluaUtwNzJfT01HLUFERlMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcnppbmlLcDcyIiwiY29nbml0bzp1c2VybmFtZSI6Ik9NRy1BREZTX2NoYW5kcnUucG9ubnVzYW15QGFubmFsZWN0LmNvbSIsImdpdmVuX25hbWUiOiJDaGFuZHJ1IiwiYXVkIjoiNzM3MGlzOXZnb2Ewb2g2OXR2MHY2dDAxdXUiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJjaGFuZHJ1LnBvbm51c2FteUBhbm5hbGVjdC5jb20iLCJwcm92aWRlck5hbWUiOiJPTUctQURGUyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwOlwvXC9vbWdzc28ub25lb21nLmNvbVwvYWRmc1wvc2VydmljZXNcL3RydXN0IiwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDE5OTU4MzIxMTEifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA3MzM4MjM0LCJleHAiOjE2MDkzMDg5MzUsImlhdCI6MTYwOTMwNTMzNSwiZmFtaWx5X25hbWUiOiJQb25udXNhbXkifQ.A66H0Vuv-pxNRKKOvG6BXsu4bK-Q0NfI5ORK8A8F8Vc59-RwHnuGc4tOL9TZT215YGA8kAh3o4WVs0YMFwXX5hmt7jKOCKKQyCjDhoLMPKNDVZ5eWULEoXVqHky5ZewvVc5y3Evo4rLsfHnyT1OC4j24oc8lwFFOshnqGGzkVNQ8zE4z39MdlioC9zQVxSzaxbAbnyol5qHQWQMXFhLHZOlrQ_5qxYirGdeZd0W7gdY6W8Asqj6thQ37tLf9537B6H54UKPxVeQYzxazbgsWqOFYs8jcMVTxI8dEIK25V-LgPf5Lba3BhqqaVGU_kVcPHH0qzmtRr38tfMIsZi-2Bg'},
                // headers: { "My-Awesome-Header": "header value", GUID: localStorage.getItem("UploadfileId") },
                init: function () {
                    this.on("addedfile", function (file) {
                        if (this.files.length > 5) {
                            this.removeFile(file);
                            window.alert("Maximum File Upload limit is 5!");
                        }
                    });

                    this.on("removedfile", function () { });
                },
            },
            ddl: 0,
            Countryilst: [],
            StateList: [],
            validationtextbox: false,
        }
    },
    validations: {
        ProdEXPAmend: {
            BankLetter: {
                minLength: minLength(1),
                required: required
            },
            BankName: {
                required: required
            },
            BankCountry: {
                required: required
            },
            BankAddressLine1: {
                required: required
            },
            BankCity: {
                required: requiredIf(function (model) {
                    return model.BankCountry == this.CountryUnitedStatesValue;
                }),
            },
            BankState: {
                required: requiredIf(function (model) {
                    return model.BankCountry == this.CountryUnitedStatesValue;
                }),
            },
            BankZipCode: {
                required: requiredIf(function (model) {
                    return model.BankCountry == this.CountryUnitedStatesValue;
                }),
                minLength: minLength(5),
                numeric,
            },
            AccountName: {
                required: required
            },
            AccountNumber: {
                required: required,
                numeric,
            },
            RoutingNumber: {
                required: requiredIf(function (model) {
                    return model.BankCountry == this.CountryUnitedStatesValue;
                }),
                maxLength: maxLength(9),
                minLength: minLength(5),
                numeric,
            },
            BankBIC: {
                required: requiredIf(function (model) {
                    return model.BankCountry != this.CountryUnitedStatesValue;
                }),
            },
            BankIBAN: {
                required: requiredIf(function (model) {
                    return model.BankCountry != this.CountryUnitedStatesValue;
                }),
            },
            RemittanceAdviceEmail: {
                required: required,
                email: EmailWithoutCase
            },
            IsBankingInfoCollected: {
                required: requiredIf(function (model) {
                    return model.IsCreditCardAccepted == "false" || model.IsCreditCardAccepted == false;
                })
            },
            CreditCardFiles: {
                minLength: minLength(1),
                required: requiredIf(function () {
                    return (this.IsCreditCardNotAccepted && !this.IsBankingInfoNotCollected) || this.IsCreditCardNotAccepted;
                })
            },
            AreThereConditionsOrLimitations: {
                required: requiredIf(function (model) {
                    return model.IsCreditCardAccepted == "true" || model.IsCreditCardAccepted == true;
                })
            },
            // AddressLine2: {
            //     required: requiredIf(function (model) {
            //         return (
            //             !this.ShowAddProduct &&
            //             model.BankCountry != "USA - United States"
            //         );
            //     }),
            // },
            // StreetAddress: {
            //     required: requiredIf(function (model) {
            //         return (
            //             !this.ShowAddProduct &&
            //             model.BankCountry == this.CountryUnitedStatesValue
            //         );
            //     }),
            // },
        }
    },
    mounted() {
        this.LoadDropdownValues();
    },
    computed: {
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('prodexpamend', {
            ProdEXPAmend: 'ProdEXPAmend',
        }),
        IsCreditCardNotAccepted() {
            return this.ProdEXPAmend.IsCreditCardAccepted == 'false' || this.ProdEXPAmend.IsCreditCardAccepted == false;
        },
        IsCreditCardYesAccepted() {
            return this.ProdEXPAmend.IsCreditCardAccepted == 'true' || this.ProdEXPAmend.IsCreditCardAccepted == true;
        },
        IsBankingInfoNotCollected() {
            return this.ProdEXPAmend.IsBankingInfoCollected == 'false' || this.ProdEXPAmend.IsBankingInfoCollected == false;
        },
        IsErrorMessage() {
            return this.IsCreditCardNotAccepted && this.IsBankingInfoNotCollected;
        },
        IsCountryUSA() {
            return this.ProdEXPAmend.BankCountry != null ?
                this.ProdEXPAmend.BankCountry == this.CountryUnitedStatesValue :
                null;
        },
    },
    watch: {

        "ProdEXPAmend.BankCountry": function (val) {
            if(val == this.CountryUnitedStatesValue)  {
                this.ProdEXPAmend.BankBIC = '';
                this.ProdEXPAmend.BankIBAN = '';
            }
            else{
                this.ProdEXPAmend.BankState = '';
                this.ProdEXPAmend.BankCity = '';
                this.ProdEXPAmend.BankZipCode = '';
                this.ProdEXPAmend.RoutingNumber = '';
            }
        }
    },

    methods: {
        mountedBankLetterDropzone() {
            if (this.ProdEXPAmend.IsEditDataLoaded == true && this.IsEdit && this.IsBankLetterLoadedInEdit == false) {
                this.ManuallyUploadFilesBankLetter(this.ProdEXPAmend.BankLetter);
                this.$set(this.ProdEXPAmend, "BankLetter", this.GetFilesObject());
                this.BankLetterFile = this.$refs.myVueDropzoneBankLetter.dropzone.files.length;
            }
        },
        CancelPaymentInfo() {
            this.ShowPaymentInfo = false;
        },
        UploadedtoBankLetter() {
            this.BankLetterFile = this.$refs.myVueDropzoneBankLetter.dropzone.files.length;
            this.$set(this.ProdEXPAmend, "BankLetter", this.GetFilesObject());
        },
        RemovedFromBankLetter() {
            this.BankLetterFile = this.$refs.myVueDropzoneBankLetter.dropzone.files.length;
            this.$set(this.ProdEXPAmend, "BankLetter", this.GetFilesObject());
        },
        GetFilesObject() {
            let files = [];
            if (this.$refs.myVueDropzoneBankLetter.dropzone.files && this.$refs.myVueDropzoneBankLetter.dropzone.files.length > 0) {
                let filesExists = this.$refs.myVueDropzoneBankLetter.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        ManuallyUploadFilesBankLetter(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded) => {
                    let file = {
                        size: fileUploaded.Length,
                        name: fileUploaded.Name,
                        type: fileUploaded.Extension,
                    };
                    var url = fileUploaded.FullName;
                    this.$refs.myVueDropzoneBankLetter.manuallyAddFile(file, url);
                });
            }
            this.IsBankLetterLoadedInEdit = true;
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
        PrintValidation() {
            const params = Object.keys(this.$v.ProdEXPAmend.$params);
            var invalidFields = [];
            for (var x = 0; x < params.length; x++) {
                let name = params[x];
                if (this.$v.ProdEXPAmend[name].$invalid) {
                    invalidFields.push(name);
                }
            }
        },
        SetDirty(propertyName) {
            this.$v.ProdEXPAmend[propertyName].$touch();

        },
        ResetDirty(propertyName) {
            this.$v.ProdEXPAmend[propertyName].$reset();
        },
        forminvalid() {
            if (this.BankLetterFile <= 0) {
                return true;
            } else {
                return false;
            }
        },
        ValidateZipCode() {
            if (
                this.BankZipCode && this.BankZipCode.length != 0 &&
                this.BankZipCode.length <= 5
            ) {
                this.validationtextbox = true;
            } else {
                this.validationtextbox = false;
            }
        },
        LoadDropdownValues() {
            this.LoadStates();
            service
                .getEntityRecords("Markets")
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.Countryilst = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexUSA = this.Countryilst.findIndex(
                            (i) => i.label == this.CountryUnitedStatesValue
                        );
                        let indexCanada = this.Countryilst.findIndex(
                            (i) => i.label == "CAN - Canada"
                        );
                        if (indexCanada > -1)
                            this.Countryilst.unshift(
                                this.Countryilst.splice(indexCanada, 1)[0]
                            );
                        if (indexUSA > -1)
                            this.Countryilst.unshift(this.Countryilst.splice(indexUSA, 1)[0]);
                        this.Countryilst.forEach(element => { element.value = element.label;});
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        async LoadStates() {
            service
                .getEntityRecords("States")
                .then((res) => {
                    this.StateList = res.data;
                    this.StateList.forEach(element => { element.value = element.label;});
                })
                .catch((err) => {
                    alert(err);
                });
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Production Expense Amend Employee Relationship",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Production Expense Amend Employee Relationship"
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Production Expense Amend Setup Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Production Expense Amend Setup Info"
                });
            }
        },
    },

    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
}
</script>

<style>
.banking-info .floating-label-input div+label {
    visibility: hidden !important;
}

.banking-info fieldset.form-group:not(.custom-radio)>legend {
    padding-left: 0px !important;
}




.banking-info .error-message {
    padding-top: 10px;
    padding-bottom: 5px;
    height: 14px !important;
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #df5678 !important;
}

.banking-info .floating-label-input {
    margin-top: 0px !important;
}

.banking-info .fieldborder {
    padding-bottom: 30px !important;
    margin-top: -22px !important;
}

.banking-info .omniipclass {
    /* width: 415px !important; */
    height: 35px !important;
    border: #e9eef0 !important;
    /* style="width:415px; height:35px;" */
}

.banking-info .Text {
    width: 250px;
    height: 196px;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.banking-info .Text .text-style-1 {
    font-weight: 600;
}

/*file upload starts*/

.banking-info .customdropzone {
    width: 411px !important;
    height: 100% !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.banking-info .Drag-and-drop-file-h {
    margin-left: 18px !important;
    margin-top: 14px !important;
    width: 387px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    /* color: #a2a9ad !important; */
}

.banking-info .draganddropspan {
    color: #00a1d2;
}

/*file upload ends*/
</style>
